import React, { ChangeEvent, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SnackBar from '@material-ui/core/Snackbar';
import IconClose from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';
import isEmail from 'validator/es/lib/isEmail';
import styles from './style';
import { sendMailContact } from '../../../../api/sendMail';
import { checkPhoneNumber } from '../../../../Services/validation';

interface PropTypes {
  title?: string;
}

interface StateInterface {
  name: string;
  tel: string;
  mail: string;
  subject: string;
  message: string;
  isAccept: boolean;
}
const Form = ({ title }: PropTypes) => {
  const classes = styles();
  const [input, setInput] = useState<StateInterface>({
    name: '',
    tel: '',
    mail: '',
    subject: '',
    message: '',
    isAccept: false,
  });

  const [snackBar, setSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState('');
  const handleClose = () => {
    setSnackBar(false);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleChangeCheckBox = (event: ChangeEvent<{}>, isAccept: boolean) => {
    setInput({ ...input, isAccept });
  };

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isEmail(input.mail)) {
      setMessageSnackBar("L'email n'est pas valide.");
      setSnackBar(true);
      return;
    }

    if (!checkPhoneNumber(input.tel)) {
      setMessageSnackBar("Le numéro de téléphone n'est pas valide");
      setSnackBar(true);
      return;
    }
    const { mail, name, subject, tel, message } = input;

    const initState = () => {
      setInput({
        name: '',
        tel: '',
        mail: '',
        subject: '',
        message: '',
        isAccept: true,
      });
    };

    sendMailContact(
      { mail, name, subject, tel, message },
      setSnackBar,
      setMessageSnackBar,
      initState,
    );
  };

  return (
    <Box className={classes.formWrapper}>
      <Typography
        variant="h3"
        className={classnames('titreSection', classes.formTitle)}
      >
        {ReactHtmlParser(title || '')}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          className={classes.textfield}
          variant="outlined"
          placeholder="Nom *"
          value={input.name}
          name="name"
          onChange={handleChangeInput}
          required={true}
          InputLabelProps={{
            style: {
              color: '#ffffff',
            },
          }}
        />
        <TextField
          className={classes.textfield}
          variant="outlined"
          placeholder="Téléphone *"
          required={true}
          value={input.tel}
          name="tel"
          onChange={handleChangeInput}
          InputLabelProps={{
            style: {
              color: '#ffffff',
            },
          }}
        />
        <TextField
          className={classes.textfield}
          variant="outlined"
          placeholder="Email *"
          required={true}
          value={input.mail}
          name="mail"
          onChange={handleChangeInput}
          InputLabelProps={{
            style: {
              color: '#ffffff',
            },
          }}
        />

        <TextField
          className={classes.textfield}
          variant="outlined"
          placeholder="Sujet *"
          required={true}
          value={input.subject}
          name="subject"
          onChange={handleChangeInput}
          InputLabelProps={{
            style: {
              color: '#ffffff',
            },
          }}
        />

        <TextField
          className={classes.textfield}
          variant="outlined"
          placeholder="Message"
          multiline
          rows={5}
          fullWidth
          required={true}
          value={input.message}
          name="message"
          onChange={handleChangeInput}
          InputLabelProps={{
            style: {
              color: '#ffffff',
            },
          }}
        />
        <FormControlLabel
          className={classes.formControlLabel}
          control={<Checkbox name="" />}
          label="En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de devis et de la relation commerciale qui peut en découler."
          value={input.isAccept}
          onChange={handleChangeCheckBox}
        />
        <Box textAlign="center">
          <Button
            className={classes.btnSubmit}
            disabled={!input.isAccept}
            type="submit"
          >
            Envoyer
          </Button>
        </Box>
      </form>
      <SnackBar
        open={snackBar}
        message={messageSnackBar}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleClose}
        action={
          <IconButton onClick={handleClose} size="small" color="inherit">
            <IconClose />
          </IconButton>
        }
      />
    </Box>
  );
};

export default Form;
