import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  formWrapper: {
    maxWidth: 577,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 992px)': {
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: 0,
      marginTop: 25,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 992px)': {
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  formTitle: {
    marginTop: theme.spacing(2),
    '& span': {
      color: '#D34246',
      fontWeight: '700 !important',
    },
  },
  textfield: {
    color: '#fff',
    fontSize: '0.825rem',
    marginBottom: theme.spacing(2.5),
    '& div': {},
    '& input, & textarea': {
      color: '#fff',
      fontSize: '0.825rem',
    },
    '& input': {
      paddingTop: 12,
      paddingBottom: 12,
    },
    '& input::placeholder': {
      color: '#fff',
    },
    '& fieldset': {
      color: '#fff',
      borderColor: '#fff!important',
      '& legend': {
        color: '#fff',
      },
    },
  },
  formControlLabel: {
    alignItems: 'flex-start',
    display: 'flex',
    '& .MuiCheckbox-root': {
      paddingTop: 0,
    },
    '& svg': {
      color: '#fff',
    },
    '& span': {
      fontSize: '0.75rem',
    },
  },
  btnSubmit: {
    color: '#fff !important',
    backgroundColor: '#D34246 !important',
    width: '100%',
    letterSpacing: 1.25,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: theme.spacing(4),
    '@media(max-width: 992px)': {
      width: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:hover, &:focus': {
      opacity: '0.75',
      backgroundColor: '#D34246 !important',
    },
  },
}));
