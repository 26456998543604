import React from 'react';
import Layout from '../Layout';
import { Box, Typography, Link } from '@material-ui/core';

import styles from './style';
import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';
import Form from './Form/Form';
interface PropTypes {
  title?: string;
  description?: string;
  mailto?: string;
  formTitle?: string;
}
const ContactForm = ({ title, description, mailto, formTitle }: PropTypes) => {
  const classes = styles();
  return (
    <Box id="contact-form" className={classes.contactFormWrapper}>
      <Layout className={classes.contactFormContainer}>
        <Box className={classes.contactFormColumn}>
          <Box
            display="flex"
            flexDirection="column"
            className={classnames(classes.contactFormTitleWrapper)}
          >
            <Typography
              variant="h2"
              className={classnames('titreSection', classes.contactFormTitle)}
            >
              {ReactHtmlParser(title || '')}
            </Typography>

            <Typography
              className={classnames(
                'textParagraphe',
                classes.contactFormParagraph,
              )}
            >
              {description}
            </Typography>
            <Link
              href={`mailto:${mailto}`}
              target="_blank"
              className={classnames(
                'textParagraphe',
                classes.contactFormMailto,
              )}
            >
              {mailto}
            </Link>
            <Typography variant="caption" className={classes.copyRight}>
              &copy; HaiRun Technology {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.contactFormColumn}>
          <Box
            display="flex"
            flexDirection="column"
            className={classnames(classes.contactForm)}
          >
            <Form title={formTitle} />
          </Box>
        </Box>
      </Layout>
    </Box>
  );
};

export default ContactForm;
