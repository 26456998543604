import { makeStyles } from '@material-ui/core';
import Bg1 from '../../../images/element_contact1.svg';
import Bg2 from '../../../images/element_contact2.svg';

export default makeStyles((theme) => ({
  contactFormWrapper: {
    backgroundColor: 'rgb(14, 12, 56)',
    width:'100%',
    color: '#fff',
  },
  contactFormContainer: {
    // maxWidth: 1460,
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 992px)': {
      width: '100%',
      maxWidth: '90%',
      flexWrap: 'wrap',
    },
  },
  contactFormTitleWrapper: {
    maxWidth: 700,
    marginLeft: 'auto',
    paddingRight: 15,
    paddingLeft: 15,
    '@media (max-width: 992px)': {
      width: '100%',
      maxWidth: '100%',
      flexWrap: 'wrap',
      paddingRight: 0,
    },
  },
  contactFormTitle: {
    marginTop: theme.spacing(2),
    '&:after': {
      backgroundColor: '#D34246',
    },
  },
  contactFormParagraph: {
    color: '#CCCCCC',
    fontSize: '1.5rem',
    '@media (max-width: 1024px)': {
      fontSize: '1.25rem',
    },
  },
  contactFormMailto: {
    color: '#FFFFFF !important',
    fontSize: '2.125rem',
    fontFamily: 'Josefin Sans',
    fontWeight: 700,
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(2),
    '@media (max-width: 1024px)': {
      fontSize: '1.5rem',
    },
    '@media (max-width: 992px)': {
      marginTop: theme.spacing(2),
    },
  },
  copyRight: {
    fontSize: '0.875rem',
    '@media (max-width: 992px)': {
      // display: 'none',
    },
  },
  contactForm: {
    maxWidth: 730,
    marginRight: 'auto',
    paddingRight: 15,
    paddingLeft: 15,
    '@media (max-width: 992px)': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  contactFormColumn: {
    paddingTop: 100,
    paddingBottom: 100,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: 'transparent',

    width: '50%',
    '@media (max-width: 992px)': {
      backgroundImage: 'none !important',
      paddingTop: 25,
      paddingBottom: 25,
      width: '100%',
    },
    '&:first-child': {
      backgroundImage: `url(${Bg1})`,
    },
    '&:last-child': {
      backgroundImage: `url(${Bg2})`,
    },
  },
}));
